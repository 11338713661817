import React, { Component } from "react"
import "./modalStyles.scss"
import ReactModal from "react-modal"
import { useStaticQuery, graphql, Link } from "gatsby"
import * as style from "../pricing/pricingGrid/style.module.scss"
import { ReactComponent as CloseSvg } from "./modalClose.svg"
import PricingContent from "./pricingContent"
import MobilePricingContent from "./mobilePricingContent"

class PricingModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
    }
  }
  componentDidMount() {
    ReactModal.setAppElement("#main")
  }
  handleModalOpen = event => {
    this.setState({ isModalOpen: true })
  }

  handleModalClose = event => {
    this.setState({ isModalOpen: false })
  }

  render() {
    return (
      <div className={style.gridButtonPopUpContainer}>
        <button
          className={style.gridButtonPopUp}
          id="main"
          onClick={this.handleModalOpen}
        >
          View Included Features
        </button>

        <ReactModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.handleModalClose}
        >
          <div className="gridScroll">
            <PricingContent />
            <MobilePricingContent />
          </div>
          <button className="closeBtn" onClick={this.handleModalClose}>
            <CloseSvg />
          </button>
        </ReactModal>
      </div>
    )
  }
}

export default PricingModal
