import * as style from "./style.module.scss"

import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useContext, useEffect, useState } from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import { LocationsContext } from "../../../contexts/locationsContext"
import MyLink from "../../myLink.js"
import PricingModal from "../../pricingModal"
// Import Tick
import { ReactComponent as Tick } from "./tick.svg"
import { v4 as uuid } from "uuid"

const PricingGrid = () => {
  //

  //Data
  const { sanityPricingPage: data } = useStaticQuery(graphql`
    query pricingGridQuery {
      sanityPricingPage {
        pricingGridMain {
          pricingGrid {
            loactions {
              locations {
                countryCode
              }
            }
            requestDemoButton {
              _key
              url
              newwindow
              linktext
              internallink
            }
            term
            titleIcon {
              asset {
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
              alt
            }
            priceNumber
            idealFirmSizeText
            idealFirmSizeTitle
            teamIcon {
              asset {
                url
                gatsbyImageData(
                  width: 50
                  layout: CONSTRAINED
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
              caption
              alt
            }
            keyFeatures
          }
        }
      }
    }
  `)

  const { currentLocation } = useContext(LocationsContext)
  const [integrations, setIntegrations] = useState(
    data.pricingGridMain.pricingGrid
  )

  useEffect(() => {
    if (currentLocation.countryCode === "gl") {
      setIntegrations(data.pricingGridMain.pricingGrid)
    } else {
      const filteredData = data.pricingGridMain.pricingGrid.filter(
        integrations =>
          integrations.loactions && // prevent error, if it's null, return false
          integrations.loactions.locations
            .map(location => location.countryCode) // reconstruct country code as array of string
            .includes(currentLocation.countryCode) // chech if the country code same as current country code, then return true
      )
      setIntegrations(filteredData)
    }
  }, [currentLocation])

  return (
    <div className={style.wrapper}>
      <div className={style.wrapperInner}>
        <div className={style.gridCont}>
          {integrations.length > 0 ? (
            integrations.slice(0, 3).map(gridItem => (
              <div className={style.gridItem}>
                <GatsbyImage
                  image={gridItem.titleIcon.asset.gatsbyImageData}
                  className={style.titleIcon}
                  imgStyle={{ objectFit: "contain" }}
                  alt={gridItem.titleIcon.alt}
                />
                {gridItem.priceNumber && (
                  <p className={style.price}>
                    {currentLocation.currencySymbol || "$"}
                    {gridItem.priceNumber}
                    <span>{currentLocation.currencyCode || "USD"}</span>
                  </p>
                )}
                {gridItem.priceNumber && (
                  <p className={style.priceTerm}>{gridItem.term}</p>
                )}
                <div className={style.firmSize}>
                  <GatsbyImage
                    image={gridItem.teamIcon.asset.gatsbyImageData}
                    className={style.youFirmIcon}
                    imgStyle={{ objectFit: "contain" }}
                    alt="Pricing grid"
                  />
                  <p>{gridItem.idealFirmSizeTitle}</p>
                </div>

                <p>{gridItem.idealFirmSizeText}</p>
                <ul className={style.featuresList}>
                  {gridItem.keyFeatures.map(feature => {
                    return (
                      <div className={style.keyFeature} key={uuid()}>
                        <Tick />
                        <li>{feature}</li>
                      </div>
                    )
                  })}
                </ul>
                <div className={style.pricingGridButtons}>
                  <PricingModal />
                  <MyLink
                    {...gridItem.requestDemoButton}
                    className={style.requestButton}
                  />
                </div>
              </div>
            ))
          ) : (
            <p className="empty-notice">
              Sorry, no partners avalible in region:{" "}
              {currentLocation.countryName}
            </p> // if no integration avalible, return this paragraph
          )}
        </div>
      </div>
    </div>
  )
}

export default PricingGrid
