import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// Components
import Layout from "../components/layout"
import SEO from "../components/pricing/seo"
import HeroBanner from "../components/pricing/herobanner"
import PricingGrid from "../components/pricing/pricingGrid"
import MoreInfo from "../components/pricing/moreInfoCta"
import PricingText from "../components/pricing/pricingText"

const PricingPage = () => {
  return (
    <Layout>
      <SEO />
      <HeroBanner />
      <PricingGrid />
      <PricingText />
      <MoreInfo />
    </Layout>
  )
}

export default PricingPage
