import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import * as style from "./innerModal.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { v4 as uuid } from "uuid"
import { ReactComponent as TickSvg } from "../pricing/pricingGrid/tick.svg"
import CtaButton from "../global/ctaIllustration/CtaButton"

import MyLink from "../myLink"

const PricingContent = () => {
  // Data
  const { sanityPricingPage: data } = useStaticQuery(graphql`
    query mobilePopUp {
      sanityPricingPage {
        pricingFeatures {
          pricingTitle
          pricingText
          pricingFeatureList {
            featureTitle
            pracPro
            pracProPlus
            expPro
          }
          practiceProIcon {
            alt
            asset {
              url
              gatsbyImageData(
                width: 200
                layout: CONSTRAINED
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          pracAcountIcon {
            alt
            asset {
              url
              gatsbyImageData(
                width: 200
                layout: CONSTRAINED
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          expressIcon {
            alt
            asset {
              url
              gatsbyImageData(
                width: 200
                layout: CONSTRAINED
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          podImage {
            alt
            asset {
              url
              gatsbyImageData(
                width: 200
                layout: CONSTRAINED
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          pricingLink {
            url
            newwindow
            linktext
            linkDisplay
            kind
            internallink
          }
        }
      }
    }
  `)
  return (
    <div className={style.mobileContent}>
      <div className={style.ContentTitle}>
        <GatsbyImage
          image={data.pricingFeatures.podImage.asset.gatsbyImageData}
          className={style.titleIcon}
          imgStyle={{ objectFit: "contain" }}
          alt={data.pricingFeatures.podImage.alt}
        />
        <h2>{data.pricingFeatures.pricingTitle}</h2>
      </div>
      <div className={style.ContentBlurb}>
        <p>{data.pricingFeatures.pricingText}</p>
      </div>
      <div className={style.ContentButton}>
        <MyLink {...data.pricingFeatures.pricingLink} />
      </div>
      <div className={style.ContentGrid}>
        <div className={style.mobileContentGrid}>
          <div>
            {/* Feature Exp Pro Tick Grid */}
            <div className={style.iconGridWrapper}>
              <GatsbyImage
                image={data.pricingFeatures.expressIcon.asset.gatsbyImageData}
                className={style.mobileIconImage}
                alt={data.pricingFeatures.expressIcon.alt}
              />
            </div>

            <ul className={style.mobileTitleList}>
              {data.pricingFeatures.pricingFeatureList.map(title => {
                if (title.expPro)
                  return (
                    <div className={style.tickList}>
                      <TickSvg />
                      <li key={uuid()} className={style.featureTitle}>
                        {title.featureTitle}
                      </li>
                    </div>
                  )
              })}
            </ul>
          </div>
          <div>
            {/* Feature Prac Pro Tick Grid */}
            <div className={style.iconGridWrapper}>
              <GatsbyImage
                image={
                  data.pricingFeatures.practiceProIcon.asset.gatsbyImageData
                }
                className={style.iconImage}
                alt={data.pricingFeatures.expressIcon.alt}
              />
            </div>

            <ul className={style.titleList}>
              {data.pricingFeatures.pricingFeatureList.map(title => {
                if (title.pracPro)
                  return (
                    <div className={style.tickList}>
                      <TickSvg />
                      <li key={uuid()} className={style.featureTitle}>
                        {title.featureTitle}
                      </li>
                    </div>
                  )
              })}
            </ul>
          </div>
          <div>
            {/* Feature Prac Pro Tick Grid */}
            <div className={style.iconGridWrapper}>
              <GatsbyImage
                image={
                  data.pricingFeatures.pracAcountIcon.asset.gatsbyImageData
                }
                className={style.iconImage}
                alt={data.pricingFeatures.expressIcon.alt}
              />
            </div>

            <ul className={style.titleList}>
              {data.pricingFeatures.pricingFeatureList.map(title => {
                if (title.pracProPlus)
                  return (
                    <div className={style.tickList}>
                      <TickSvg />
                      <li key={uuid()} className={style.featureTitle}>
                        {title.featureTitle}
                      </li>
                    </div>
                  )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PricingContent
