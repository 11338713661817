import React from "react"
import * as style from "./style.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import BlocksContent from "../../blocksContent.js"

const PricingText = () => {
  const { sanityPricingPage: data } = useStaticQuery(graphql`
    query pricingBottomGrid {
      sanityPricingPage {
        expressIcon {
          alt
          asset {
            url
            gatsbyImageData(
              width: 227
              layout: CONSTRAINED
              placeholder: NONE
              formats: [AUTO, WEBP]
            )
          }
        }
        financeOptions {
          express {
            _rawColumnContent
          }
          pro {
            _rawColumnContent
          }
          proPlus {
            _rawColumnContent
          }
        }
        pracAcountIcon {
          alt
          asset {
            url
            gatsbyImageData(
              width: 227
              layout: CONSTRAINED
              placeholder: NONE
              formats: [AUTO, WEBP]
            )
          }
        }
        practiceProIcon {
          alt
          asset {
            url
            gatsbyImageData(
              width: 227
              layout: CONSTRAINED
              placeholder: NONE
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `)

  return (
    <div className={style.wrapperInner}>
      <div className={style.gridInner}>
        <div className={style.betterGrid}>
          <div className={style.gridRow1}>
            <div className={style.iconContainer}>
              <GatsbyImage
                image={data.expressIcon.asset.gatsbyImageData}
                className={style.iconImage}
                alt={data.expressIcon.alt}
                imgStyle={{ objectFit: "contain" }}
              />
            </div>
            <div className={style.blocks}>
              <BlocksContent
                blocks={data.financeOptions.express._rawColumnContent}
              />
            </div>
          </div>
          <div className={style.gridRow2}>
            <div className={style.iconContainer}>
              <GatsbyImage
                image={data.practiceProIcon.asset.gatsbyImageData}
                className={style.iconImage}
                alt={data.expressIcon.alt}
                imgStyle={{ objectFit: "contain" }}
              />
            </div>
            <div className={style.iconContainer}>
              <GatsbyImage
                image={data.pracAcountIcon.asset.gatsbyImageData}
                className={style.iconImage}
                alt={data.expressIcon.alt}
                imgStyle={{ objectFit: "contain" }}
              />
            </div>
            {data.financeOptions.pro && (
              <div className={style.blocks}>
                <BlocksContent
                  blocks={data.financeOptions.pro._rawColumnContent}
                />
              </div>
            )}
            {data.financeOptions.proPlus && (
              <div className={style.blocks}>
                <BlocksContent
                  blocks={data.financeOptions.proPlus._rawColumnContent}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingText
