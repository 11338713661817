import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import * as style from "./innerModal.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { v4 as uuid } from "uuid"
import { ReactComponent as TickSvg } from "../pricing/pricingGrid/tick.svg"
import CtaButton from "../global/ctaIllustration/CtaButton"

import MyLink from "../myLink"

const PricingContent = () => {
  // Data
  const { sanityPricingPage: data } = useStaticQuery(graphql`
    query pricingPopUp {
      sanityPricingPage {
        pricingFeatures {
          pricingTitle
          pricingText
          pricingFeatureList {
            featureTitle
            pracPro
            pracProPlus
            expPro
          }
          pricingFeatureListOption {
            featureTitle
            pracPro
            pracProPlus
            expPro
          }
          practiceProIcon {
            alt
            asset {
              url
              gatsbyImageData(
                width: 130
                layout: CONSTRAINED
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          pracAcountIcon {
            alt
            asset {
              url
              gatsbyImageData(
                width: 132
                layout: CONSTRAINED
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          expressIcon {
            alt
            asset {
              url
              gatsbyImageData(
                width: 125
                layout: CONSTRAINED
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          podImage {
            alt
            asset {
              url
              gatsbyImageData(
                width: 156
                layout: CONSTRAINED
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          pricingLink {
            url
            newwindow
            linktext
            linkDisplay
            kind
            internallink
          }
        }
      }
    }
  `)
  return (
    <div className={style.ContentInner}>
      <div className={style.ContentTitle}>
        <GatsbyImage
          image={data.pricingFeatures.podImage.asset.gatsbyImageData}
          className={style.titleIcon}
          imgStyle={{ objectFit: "contain" }}
          alt={data.pricingFeatures.podImage.alt}
        />
        <h2>{data.pricingFeatures.pricingTitle}</h2>
      </div>
      <div className={style.ContentBlurb}>
        <p>{data.pricingFeatures.pricingText}</p>
      </div>
      <div className={style.ContentButton}>
        <MyLink {...data.pricingFeatures.pricingLink} />
      </div>
      <div className={style.ContentGrid}>
        <div className={style.ContentGridTitleIcons}>
          <div></div>
          <div>
            <GatsbyImage
              image={data.pricingFeatures.expressIcon.asset.gatsbyImageData}
              className={style.iconImage}
              alt={data.pricingFeatures.expressIcon.alt}
            />
          </div>
          <div>
            <GatsbyImage
              image={data.pricingFeatures.practiceProIcon.asset.gatsbyImageData}
              className={style.iconImage}
              alt={data.pricingFeatures.expressIcon.alt}
            />
          </div>
          <div>
            <GatsbyImage
              image={data.pricingFeatures.pracAcountIcon.asset.gatsbyImageData}
              className={style.iconImage}
              alt={data.pricingFeatures.expressIcon.alt}
            />
          </div>
        </div>
        <div className={style.ContentGridInner}>
          <div>
            {/* Feature title Row */}
            <ul className={style.titleList}>
              {data.pricingFeatures.pricingFeatureList.map((title, index) => {
                return <li key={index}>{title.featureTitle} </li>
              })}
            </ul>
          </div>
          <div>
            {/* Feature Exp Pro Tick Grid */}
            <ul className={style.titleList}>
              {data.pricingFeatures.pricingFeatureList.map((title, index) => {
                if (title.expPro)
                  return (
                    <li key={index}>
                      <TickSvg />
                    </li>
                  )
                else
                  return (
                    <li key={index}>
                      <TickSvg style={{ opacity: 0 }} />
                    </li>
                  )
              })}
            </ul>
          </div>
          <div>
            {/* Feature Prac Pro Tick Grid */}
            <ul className={style.titleList}>
              {data.pricingFeatures.pricingFeatureList.map((title, index) => {
                if (title.pracPro)
                  return (
                    <li key={index}>
                      <TickSvg />
                    </li>
                  )
                else
                  return (
                    <li key={index}>
                      <TickSvg style={{ opacity: 0 }} />
                    </li>
                  )
              })}
            </ul>
          </div>
          <div>
            {/* Feature Prac Pro Tick Grid */}
            <ul className={style.titleList}>
              {data.pricingFeatures.pricingFeatureList.map((title, index) => {
                if (title.pracProPlus)
                  return (
                    <li key={index}>
                      <TickSvg />
                    </li>
                  )
                else
                  return (
                    <li key={index}>
                      <TickSvg style={{ opacity: 0 }} />
                    </li>
                  )
              })}
            </ul>
          </div>
        </div>
        <div className={style.ContentGridInner}>
          <div>
            {/* Feature title Row */}
            <ul className={style.titleList}>
              <li>
                <strong>Optional Features</strong>
              </li>
              {data.pricingFeatures.pricingFeatureListOption.map(
                (title, index) => {
                  return <li key={index}>{title.featureTitle} </li>
                }
              )}
            </ul>
          </div>
          <div>
            {/* Feature Exp Pro Tick Grid */}
            <ul className={style.titleList}>
              {data.pricingFeatures.pricingFeatureListOption.map(
                (title, index) => {
                  if (title.expPro)
                    return (
                      <li key={index}>
                        <TickSvg />
                      </li>
                    )
                  else
                    return (
                      <li key={index}>
                        <TickSvg style={{ opacity: 0 }} />
                      </li>
                    )
                }
              )}
            </ul>
          </div>
          <div>
            {/* Feature Prac Pro Tick Grid */}
            <ul className={style.titleList}>
              {data.pricingFeatures.pricingFeatureListOption.map(
                (title, index) => {
                  if (title.pracPro)
                    return (
                      <li key={index}>
                        <TickSvg />
                      </li>
                    )
                  else
                    return (
                      <li key={index}>
                        <TickSvg style={{ opacity: 0 }} />
                      </li>
                    )
                }
              )}
            </ul>
          </div>
          <div>
            {/* Feature Prac Pro Tick Grid */}
            <ul className={style.titleList}>
              {data.pricingFeatures.pricingFeatureListOption.map(
                (title, index) => {
                  if (title.pracProPlus)
                    return (
                      <li key={index}>
                        <TickSvg />
                      </li>
                    )
                  else
                    return (
                      <li key={index}>
                        <TickSvg style={{ opacity: 0 }} />
                      </li>
                    )
                }
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PricingContent
