import React from "react"
import * as style from "./style.module.scss"
import { useStaticQuery, graphql } from "gatsby"

// Import Cta Buttons
import CtaButton from "../../global/ctaIllustration/CtaButton"
import MyLink from "../../myLink"

const MoreInfo = () => {
  // Data
  const { sanityPricingPage: data } = useStaticQuery(graphql`
    query pricingBottomCta {
      sanityPricingPage {
        bottomPageCt {
          ctaButtons {
            ... on SanityLink {
              _key
              _type
              internallink
              linktext
              newwindow
              url
            }
          }
          subTitle
          title
          ctaText
        }
      }
    }
  `)
  return (
    <div className={style.wrapper}>
      <div className={style.wrapperInner}>
        <h2>{data.bottomPageCt.title}</h2>
        <p>{data.bottomPageCt.subTitle}</p>
        <div className={style.buttonContainer}>
          {data.bottomPageCt.ctaButtons.map(button => {
            return <MyLink {...button} className={style.button} />
          })}
        </div>
      </div>
    </div>
  )
}

export default MoreInfo
