// extracted by mini-css-extract-plugin
export var bottomBorder = "style-module--bottomBorder--MBMP-";
export var button = "style-module--button--JvPQ2";
export var featuresList = "style-module--featuresList--ZHqwc";
export var firmSize = "style-module--firmSize--c7yo5";
export var gridButton = "style-module--gridButton--uYOJy";
export var gridButtonLink = "style-module--gridButtonLink--IIGX9";
export var gridButtonPopUp = "style-module--gridButtonPopUp--GRBbA";
export var gridButtonPopUpContainer = "style-module--gridButtonPopUpContainer--4uNQb";
export var gridCont = "style-module--gridCont--veMUC";
export var gridItem = "style-module--gridItem--lW6yk";
export var gridTitle = "style-module--gridTitle--ftwWf";
export var isStyleH1 = "style-module--is-style-h1--BnKk3";
export var isStyleH2 = "style-module--is-style-h2--YtPLC";
export var isStyleH3 = "style-module--is-style-h3--I2uOB";
export var isStyleH4 = "style-module--is-style-h4--ke0IL";
export var isStyleH5 = "style-module--is-style-h5--SFdv0";
export var isStyleH6 = "style-module--is-style-h6--csWMx";
export var keyFeature = "style-module--keyFeature--TkMRV";
export var link = "style-module--link--BeG0Y";
export var modal = "style-module--modal--EkAKx";
export var price = "style-module--price--OAuLF";
export var priceTerm = "style-module--priceTerm--D0u4q";
export var pricingGridButtons = "style-module--pricingGridButtons--+tmOU";
export var requestButton = "style-module--requestButton--r2GiB";
export var titleIcon = "style-module--titleIcon--mM5Zm";
export var wrapper = "style-module--wrapper--kFEPg";
export var wrapperInner = "style-module--wrapperInner--pmoC6";
export var youFirmIcon = "style-module--youFirmIcon--NLJSV";